import React, { Component, Suspense } from "react";

import { Switch, Route, Router } from "react-router-dom";
import history from "./history";

import MiniLoading from "../components/MiniLoading"

const Recovery = React.lazy(() => import("../pages/Recovery"));
const ScrollToTop = React.lazy(() => import("./scrollToTop"));
const Login = React.lazy(() => import("../pages/Login"));
const SignIn = React.lazy(() => import("../pages/SignIn"));
const Billet = React.lazy(() => import("../pages/Charges/Billet"));
const Structure = React.lazy(() => import("../pages/Structure"));

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Suspense fallback={<MiniLoading maxHeight="100vh" minHeight="100vh" />}>
          <Router history={history}>
            <ScrollToTop />
            <Route
              path="/"
              exact
              render={(props) => (
                <Login config={this.props.stateConfig} {...props} />
              )}
            />
            <Route
              path="/recuperar"
              exact
              render={(props) => (
                <Recovery config={this.props.stateConfig} {...props} />
              )}
            />
            <Route
              path="/dashboard"
              render={(props) => (
                <Structure config={this.props.stateConfig} {...props} />
              )}
            />
            <Route
              path="/cadastro/:ref?"
              render={(props) => (
                <SignIn config={this.props.stateConfig} {...props} />
              )}
            />
            <Route
              path="/segunda-via-boleto"
              render={(props) => (
                <Billet config={this.props.stateConfig} {...props} />
              )}
            />
          </Router>
      </Suspense>
        </Switch>
    );
  }
}

export default Routes;
