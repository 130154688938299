import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
})

const user = localStorage.getItem("JETO#USER");

if (user) {
    api.defaults.headers.common['authorization'] = `Bearer ${JSON.parse(user).token}`;
}


export default api;