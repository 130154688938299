import api from "./api";

const getPolicies = async () => {
  return await api.get("politicasVigentes");
};

const validate = async (pin) => {
  return await api.post("/api/perfil/aceitarTermosDeUso", { pin });
};

export const policiesServices = { getPolicies, validate };
