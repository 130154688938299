import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: 'SegoeUI';
        src:
            local("Segoe UI Light"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2') format("woff2"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff') format("woff"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf') format("truetype");
        font-weight: 100;
    }

    @font-face {
        font-family: 'SegoeUI';
        src:
            local("Segoe UI Semilight"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2') format("woff2"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff') format("woff"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf') format("truetype");
        font-weight: 200;
    }

    @font-face {
        font-family: 'SegoeUI';
        src:
            local("Segoe UI"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2') format("woff2"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff') format("woff"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf') format("truetype");
        font-weight: 400;
    }

    @font-face {
        font-family: 'SegoeUI';
        src:
            local("Segoe UI Bold"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2') format("woff2"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff') format("woff"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf') format("truetype");
        font-weight: 600;
    }

    @font-face {
        font-family: 'SegoeUI';
        src:
            local("Segoe UI Semibold"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2') format("woff2"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff') format("woff"),
            url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf') format("truetype");
        font-weight: 700;
    }
    * {
        max-width: 100vw;
        box-sizing: border-box;
        padding: 0;
        margin: 0;

        &:focus {
            outline: transparent !important
        }
    }

    body {
        box-sizing: border-box;
        max-width: 100vw !important;
        font-weight: 400;
        overflow-x: hidden;
        font-family: 'SegoeUI';
        color: black;
    }

    :root {
        --blue: #2133c5;
        --blueDark: #0F1759;
        --yellow: #FFBC20;
        --green: #57DE4D;
        --red: #FF2E2E;
    }

    ::-webkit-scrollbar {
        appearance: none;
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgba(214, 214, 214, 0.8);
        border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
        background-color: #ffffff;
        box-shadow:  0 0 1px rgba(174, 174, 174, 0.9);
        border-radius: 5px;
    }

    /* FONTS */
    .montserratBold {
        font-family: "Montserrat";
        font-weight: bold
    }
    /* FIX */
    input, textarea {
        padding-left: 10px !important;
        width: calc(100% - 10px) !important;
        margin-bottom: 0px !important;
    }

    input {
        height: 40px !important;
        line-height: 40px !important;
    }

    a, img {
        display: block;
    }
    /* COLORS */
    .bgBlue {
        background-color: var(--blue);
    }

    .bgYellow {
        background-color: var(--yellow);
    }

    .bgGreen {
        background-color: var(--green);
    }

    .bgRed {
        background-color: var(--red);
    }

    .colorBlue {
        color: var(--blue) !important
    }

    .colorYellow {
        color: var(--yellow)
    }

    .colorGreen {
        color: var(--green)
    }

    .colorRed {
        color: var(--red)
    }
    /* FONT */
    .bold {
        font-weight: 700;
    }

    .semibold {
        font-weight: 600;
    }
    /* SPACING */
    .margin_05_right {
        margin-right: .5rem
    }

    .margin_05_top {
        margin-top: .5rem;
    }

    .margin_05_bottom {
        margin-bottom: .5rem;
    }

    .margin_1_bottom {
        margin-bottom: 1rem;
    }

    .margin_1_top {
        margin-top: 1rem;
    }

    .margin_1_left {
        margin-left: 1rem;
    }

    .margin_2_top {
        margin-top: 2rem;
    }

    .margin_3_top {
        margin-top: 3rem;
    }

    .padding_05_top {
        padding-top: .5rem;
    }

    .padding_1_top {
        padding-top: 1rem;
    }

    .padding_2_top {
        padding-top: 2rem;
    }

    .padding_3_top {
        padding-top: 3rem;
    }
    /* POSITION */
    .relative {
        position: relative
    }
    /* DISPLAY */
    .flex {
        display: flex
    }
    /* ALIGNMENT */
    .alignCenter {
        align-items: center;
    }
    /* JUSTIFY */
    .justifyEnd {
        justify-content: flex-end;
    }
    .justifyBetween {
        justify-content: space-between
    }
    .justifyCenter {
        justify-content: center;
    }
    /* TEXT */
    .textUpper {
        text-transform: uppercase
    }
    /* SIZES */
    .widthfull {
        width: 100%;
    }

    h5 {
        font-size: 18px;
    }

    .ant-layout-content {
        background-color: #E3E3E3;
        display: grid;
        max-height: calc(100vh - 50px);
    }

    .ant-tabs-nav-operations.ant-tabs-nav-operations-hidden {
        display: none;
    }

    .pointer {cursor: pointer}

    .select-wrapper {
        position: relative;

        svg {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            transform: translateY(10px);
        }
    }

    .input-field {
        margin-top: 0px !important;
    }

    #QRCode {
        max-width: 400px;
        border-radius: 10px;

        h4 {
            display: none
        }

        .modal .modal-content {
            padding: 0 24px;
        }

        .modal-footer {
            display: flex;
            justify-content: space-between;
            padding: 0 10px;

            button.colorBlue {
                border: 1px solid var(--blue);
                display: flex;
                align-items: center;
                border-radius: 5px;

                img {
                    max-width: 20px;
                    margin-left: 7px
                }
            }

            button {
                padding: 0 15px;
            }
        }

    }

    .ant-modal-wrap.modalAntd {
        z-index: 1000000 !important;
    }

    .modalSaque .ant-modal {
        max-width: 100% !important;
        width: 350px !important;
        text-align: center;

        .ant-modal-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button.save {
                background-color: var(--blue) !important;
                border: 1px solid var(--blue) !important;
                padding: 6px 15px;
                color: white;
                text-transform: uppercase;
                border-radius: 5px;
                transition: .3s;

                &:hover {
                    background-color: white !important;
                    color: var(--green) !important;
                }

                &:disabled {
                    background-color: #ccc !important;
                    border: 1px solid #ccc !important;
                }
            }

            button.cancelar {
                border: 1px solid var(--red) !important;
                background-color: white !important;
                padding: 6px 15px;
                color: var(--red) !important;
                text-transform: uppercase;
                border-radius: 5px;
                transition: .3s;

                &:hover {
                    background-color:var(--red) !important;
                    color: white !important;
                }
            }

            button.submeter:not(.save) {
                background-color: var(--green) !important;
                border: 1px solid var(--green) !important;
                padding: 6px 15px;
                color: white;
                text-transform: uppercase;
                border-radius: 5px;
                transition: .3s;

                &:hover {
                    background-color: white !important;
                    color: var(--green) !important;
                }

                &:disabled {
                    background-color: #ccc !important;
                    border: 1px solid #ccc !important;
                }
            }

        }

    }

    .modalInfo .ant-modal {
        max-width: 100% !important;
        width: 300px;
        .ant-modal-footer {
            display: none;
        }
    }

    .modalCharge .ant-modal {
        max-width: 100% !important;
        width: 800px !important;
        color: #727272;
            background-color: #f1f1f1;
        .charge-title {
            color: #262626;
            font-size: 16px;
            font-weight: 500px
        }
        .grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            margin: 1.5rem 0;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding: 5px 0;
        }
        .parcel-item {
            border: 1px solid #b9b6b6;
            padding: 5px;
            img {
                width: 25px;
            }
            i {
                font-size: 20px;
                transition: .3s;
                &:hover {
                    color: var(--blue)
                }
            }
        }
        input:not([placeholder="Vencimento"]) {
            border: 1px solid #ccc !important;
            background-color: white;
            width: 100%;
            margin-top: 1rem;
        }
        .ant-select-selector {
            height: 42px !important;
            padding-top: 5px
        }
        .parcels {
            font-size: 13px;
        }
        .parcel-date {
            height: 30px;
            width: 15ch;
            padding-left: 0px !important;
            margin: 0 5px !important;
            border-left: 0px !important;
            border-top: 0px !important;
            border-right: 0px !important;
        }
        .ant-picker:not(.parcel-date) {
            margin-top: 1rem !important;
            width: 100%;
            padding-left: 0px !important
        }
        .ant-modal-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button.cancelar {
                border: 1px solid var(--red) !important;
                background-color: white !important;
                padding: 6px 15px;
                color: var(--red) !important;
                text-transform: uppercase;
                border-radius: 5px;
                transition: .3s;

                &:hover {
                    background-color:var(--red) !important;
                    color: white !important;
                }
            }

            button.submeter {
                &.save {
                    background-color: var(--blue) !important;
                    border: 1px solid var(--blue) !important;
                }
                background-color: var(--green) !important;
                border: 1px solid var(--green) !important;
                padding: 6px 15px;
                color: white;
                text-transform: uppercase;
                border-radius: 5px;
                transition: .3s;

                &:hover {
                    background-color: white !important;
                    color: var(--green) !important;
                }

                &:disabled {
                    background-color: #ccc !important;
                    border: 1px solid #ccc !important;
                }
            }
        }
    }

    @media (max-width: 800px) {
        .ant-tabs-nav .ant-tabs-tab {
            padding: 5px 30px;
            max-width: 50%;
        }

        .ant-list.margin_2_top {
            margin-top: 0px !important
        }

        .shape-title {
            font-size: 12px;
        }

        .modal-footer {
            flex-flow: column;
            height: 90px;
            justify-content: end;

            button {
                padding: 0 15px;
                max-width: 20ch;
                margin: 0 auto;
            }
        }

        .modal-content {
            padding: 15px;
        }

        .ant-collapse-arrow {
            display: none !important
        }
    }

    .passwordInput {
        border: 1px solid #9e9e9e !important;
        border-radius: 5px !important;
    }


  .modalCookie {
    .content {
      max-height: 50vh;
      overflow: auto;
      ::-webkit-scrollbar {
        appearance: none;
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: rgba(33, 51, 197, 0.8) !important;
        border-radius: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: #ffffff;
        box-shadow: 0 0 1px rgba(174, 174, 174, 0.9);
        border-radius: 5px;
      }
    }
  }

  .swal2-container {
      z-index: 10000000 !important
  }

  .padding-left {
      padding-left: 1em;
  }

`;
