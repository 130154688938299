import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import Session from "./store/actions/session.actions";
import { GlobalStyle } from "./assets/css/global";
import Router from "./router/router";
import "./assets/css/materialize.min.css";
import "./assets/css/antd.css";
import { handleStorage } from "./services/session";
import moment from "moment";
import "moment/locale/pt-br";
import "materialize-css";
import { policiesServices } from "./services/policies.service";
import OneSignal from 'react-onesignal';
import { useEffect } from "react";


require("dotenv").config({ path: __dirname + "../.env" });
moment.locale("pt-BR");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policies: {},
      actions: {
        updateUser: this.updateUser,
      },
    };

    this.updateUser = this.updateUser.bind(this);
  }
  
  updateUser = async (user, login = null) => {
    await this.props.sessionLogin(user);
    handleStorage("SET", user);
  };

  testStorage = async (value) => {
    if (value) {
      var soma = moment(value.expires_end).diff(moment(new Date()), "minutes");

      if (soma > 0) {
        this.updateUser(value);
      } else {
        handleStorage("REMOVE");
      }
    }
  };
  
  detectDevice() {
    this.forceUpdate();
  }

  componentDidMount() {
    this.testStorage(handleStorage("GET"));
    window.addEventListener("resize", this.detectDevice.bind(this));

    policiesServices
      .getPolicies()
      .then((res) => this.setState(() => ({ policies: res.data })))
      .catch((err) => console.log(err));
  }

  render() {
   
    return (
      <BrowserRouter>
        <GlobalStyle />
        {this.state.loading === true ? (
          <div />
        ) : (
          <Router stateConfig={this.state} />
        )}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ sessionReducers }) => ({
  session: sessionReducers,
});

const mapDispatchToProps = (dispatch) => ({
  sessionLogin: (user) => dispatch(Session.Login(user)),
});



export default connect(mapStateToProps, mapDispatchToProps)(App);
