import React from "react";
import ReactLoading from "react-loading";

import { Container } from "./styles"

const Loading = (props) => {
  return (
    <Container style={{minHeight: props.minHeight, maxHeight: props.maxHeight}}>
      <ReactLoading type="cylon"  />
    </Container>
  );
};

export default Loading;
