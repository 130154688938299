const Login = value => ({
  type: "LOGIN",
  payload: { user: value }
});

const Logout = () => ({
  type: "LOGOUT"
});

const Session = { Login, Logout };

export default Session;
