import moment from "moment";

import api from "./api";
import history from "../router/history";

const loginHasUser = async (login) => {
    return await api.post(`/api/perfil/loginPortal`, {
        email: login,
    });
};

const loginAuth = async (login, senha) => {
    return await api.post(`/api/perfil/loginPortalUsuario`, {
        email: login,
        senha: senha,
    });
};

const changeAccount = async (login, senha, codigoConta) => {
    return await api.post(`/api/perfil/loginPortalUsuario`, {
        email: login,
        senha,
        codigoConta,
    });
};

const recoverPass = async (login) => {
    return await api.post(`/api/perfil/esqueciMinhaSenha`, {
        email: login,
    });
};

const recoverPassOperator = async(login) => {
    return await api.post('/api/operador/enviarEmailAlteracaoSenha', login)
}

const handleStorage = (action, value) => {
    Date.prototype.addHours = function (h) {
        this.setHours(this.getHours() + h);
        return this;
    };

    var storage;
    switch (action) {
        case "SET":
            api.defaults.headers.common[
                "authorization"
            ] = `Bearer ${value.token}`;
            localStorage.setItem(
                "JETO#USER",
                JSON.stringify({
                    ...value,
                    expires: moment(new Date()).format(),
                    expires_end: moment(new Date()).add(15, "minutes").format(),
                })
            );
            storage = localStorage.getItem("JETO#USER");
            break;

        case "GET":
            storage = localStorage.getItem("JETO#USER");
            return JSON.parse(storage);
        case "REMOVE":
            localStorage.removeItem("JETO#USER");
            break;

        default:
            break;
    }
};

const handleLogoutError = (error, SwalTwo) => {
    if (error.response && error.response.data.message === "Expired token") {
        SwalTwo.fire({
            icon: "warning",
            title: "Oops...",
            text: "Sua sessão expirou",
        });

        setTimeout(() => {
            localStorage.removeItem("JETO#USER");
            history.push("/");
        }, 2000);

        return true;
    }

    return false;
};

const verifyLoginMethod = async (data) => {
    return await api.post("/api/perfil/loginPortal", {
        email: data,
    });
};

const accountOperatorLoad = async (data) => {
    return await api.post("/api/perfil/carregaContasOperadores", data)
}

const accountOperatorLogin = async (data) => {
    return await api.post("/api/perfil/loginPortalEmpresa", data)
}

export {
    loginHasUser,
    loginAuth,
    recoverPass,
    recoverPassOperator,
    handleStorage,
    handleLogoutError,
    changeAccount,
    verifyLoginMethod,
    accountOperatorLogin,
    accountOperatorLoad,
};
